import React from "react";
// Customizable Area Start
import {
  Button,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Box
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Customizable Area End
import AddDkrController, { Props } from "./AddDkrController";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class ViewKpi5 extends AddDkrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.maincontainerveiw}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid className="first" style={webStyle.leftSide2} item>
              <Grid container spacing={2} alignItems="center">
                <Grid item style={webStyle.rowgridDisplayveiw}>
                  <ArrowBackIosIcon style={{ fontSize: "24px" , color: "black" }} data-test-id="nextButton" onClick={this.navigateToEditTemplate2}/>
                  <span
                    style={{...webStyle.labelStylesecondveiw,fontWeight:400}}
                  >
                    Edit Template
                  </span>
                </Grid>
                <Grid item style={webStyle.rowgridDisplayveiw}>
                  <ArrowBackIosIcon style={{...webStyle.labelStylesecondveiw,color:"#B2B2B2"}} />
                  <span style={{...webStyle.labelStyle100veiw as React.CSSProperties,fontWeight:700}}>
                    View KPI Details
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="second" style={webStyle.nullContainerveiw} item />
          </Grid>
          <Grid
            container
            alignItems="center"
            style={webStyle.informContainerveiw as React.CSSProperties}
          >
            <Grid
              className="first"
              item
              style={webStyle.informationSubContainerHeadingveiw}
              xs={12}
            >
              <Box>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.headermandatoryveiw}>
                    <span style={webStyle.labelStyleveiw as React.CSSProperties}>KPI Details</span>
                  </FormLabel>

                </FormControl>
              </Box>
              <Box className="form-field text-center" id="signupBlock4">
                <Box className="control" id="signupfieldcontrol2">
                <Button style={webStyle.EditBtn as React.CSSProperties} data-test-id="nextButton1" onClick={this.navigateToEditKpi}>
                Edit KPI Details
            </Button>
                </Box>
              </Box>

            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.informSubContainerveiw}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.headermandatoryveiw}>
                  <span style={{...webStyle.labelStyle100veiw as React.CSSProperties,fontSize:"22px",fontWeight:500}}>Type</span>
                </FormLabel>
                <span style={webStyle.mainvalueStyle as React.CSSProperties}>Core</span>
              </FormControl>
            </Grid>
            <Grid
              className="second"
              item
              style={{...webStyle.informSubContainerveiw,marginTop:"53px"}}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{...webStyle.headermandatoryveiw,marginTop:"30px"}}>
                  <span style={{...webStyle.labelStyle100veiw as React.CSSProperties,fontSize:"22px",fontWeight:500}}>Basic Details</span>
                </FormLabel>
                <Grid container style={{ display: "flex" }} xs={12} spacing={6}>
                  <Grid item xs={3}>
                    <Box className="form-block" id="signupBlock3">
                      <form>
                        <Box
                          className="form-field"
                          id="signupform"
                          style={webStyle.top30}
                        >
                          <span style={webStyle.labelStylemainHeader as React.CSSProperties}>Title</span>
                          <Box
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.marginLeft0}
                          >
                            <span style={webStyle.mainvalueStyle as React.CSSProperties}>KPI-5</span>
                          </Box>
                        </Box>
                      </form>
                    </Box>
                  </Grid>
                    <Grid item xs={6}>
                      <Box className="form-block" id="signupBlock3">
                        <form>
                        <Box
                          className="form-field"
                          id="signupform"
                          style={webStyle.top30}
                        >
                          <span style={webStyle.labelStylemainHeader as React.CSSProperties}>Description</span>
                          <Box
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.marginLeft0}
                          >
                            <span style={webStyle.mainvalueStyle as React.CSSProperties}>Weekly Stock<br />Check Stock for 2 Items<br />Saturdays</span>
                          </Box>
                          </Box>
                        </form>
                      </Box>
                    </Grid>
                  <Grid item xs={3}>
                    <Box className="form-block" id="signupBlock3">
                      <form>
                        <Box
                          className="form-field"
                          id="signupform"
                          style={{...webStyle.top30,width:"max-content"}}
                        >
                          <span style={webStyle.labelStylemainHeader as React.CSSProperties}>Points (Weightage)</span>
                          <Box
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.marginLeft0}
                          >
                            <span style={webStyle.mainvalueStyle as React.CSSProperties}>10 points</span>
                          </Box>
                        </Box>
                      </form>
                    </Box>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid >
          </Grid >
          <Divider light style={{marginTop:"20px",marginBottom:"20px"}} />
          <Grid
            container
            alignItems="center"
            style={webStyle.informContainerveiw as React.CSSProperties}
          > 
          <Grid
              className="first"
              item
              style={webStyle.informationSubContainerHeadingveiw}
              xs={12}
            >
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.headermandatoryveiw}>
                    <span style={webStyle.labelStyleveiw as React.CSSProperties}>Daily KPI Report</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2">
                <Button style={webStyle.buttonEditveiw as React.CSSProperties} data-test-id="nextButton" onClick={this.navigateToAddDKR5}>
                Edit DKR Details
            </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={{...webStyle.informationSubContainerTableview,marginBottom:"10%"}}
              xs={12}
            >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.headermandatoryveiw}>
                    <span style={{...webStyle.labelStyle100veiw as React.CSSProperties,fontSize:"22px",fontWeight:500}}>Applicable DKRs</span>
                  </FormLabel>
                </FormControl>
                <TableContainer style={webStyle.tablemainContainer}>
                  <Table aria-label="customized table">
                  <TableHead>
                      <TableRow style={webStyle.maintableHead}>
                        <TableCell style={{...webStyle.Tcell2,fontWeight:600}}>Sr. No.</TableCell>
                        <TableCell style={{...webStyle.Tcell3,fontWeight:600}}>DKR Brief Title</TableCell>
                        <TableCell style={{...webStyle.Tcell3,fontWeight:600}}>
                          DKR Response
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow 
                        >
                          <TableCell component="th" scope="row" style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"65px"}}>
                            1.
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.marginLeft0}
                                  >
                                  <span style={webStyle.mainvalueStyle as React.CSSProperties}>Today's Stock</span>

                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell style={{width:"30%"}}>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                  >
                                <span style={webStyle.mainvalueStyle as React.CSSProperties}>Manual</span>

                                  </Box>

                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow 
                        >
                          <TableCell component="th" scope="row" style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"65px"}}>
                           2.
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={{marginLeft: 0, marginTop: 10}}
                                  >
                                    <span style={webStyle.mainvalueStyle as React.CSSProperties}>Average Stock</span>

                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                  >
                                <span style={webStyle.mainvalueStyle as React.CSSProperties}>Dropdown</span>

                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow 
                        style={{ backgroundColor: "#F6F6F9" }}>
                          <TableCell component="th" scope="row" style={{fontFamily:"Open Sans",fontSize:"14px",color:"black"}}>
                           Option 1.
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.marginLeft0}
                                  >
                                 <span style={webStyle.mainvalueStyle as React.CSSProperties}>Recorded stock</span>

                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box 
                             id="signupBlock3"
                            className="form-block">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    style={webStyle.marginLeft0} 
                                    id="signupfieldcontrol" >
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow 
                        style={{ backgroundColor: "#F6F6F9" }} >
                          <TableCell component="th" scope="row" style={webStyle.Tcell}>
                            Option 1.
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.marginLeft0}
                                  >
                                <span style={webStyle.mainvalueStyle as React.CSSProperties}>Current Stock</span>

                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box 
                            className="form-block"
                             id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform">
                                  <Box
                                      style={webStyle.marginLeft0} 
                                      id="signupfieldcontrol"
                                    className="control">
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            </Grid>
          </Grid >

        </Box >
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  marginLeft0: {
    marginLeft: 0,
    marginTop: 10
  },
  buttonContainer: { 
    margin: "0 40px" ,
    display: "flex",
     gap: "20px", 
    },
  headermandatoryveiw: {
    display: "flex",
    alignItems: "baseline",
    MarginTop:"30px",
    gap: "4px",
  },
  tablemainContainer: { 
    marginTop: "15px" ,
    borderRadius: "10px",
    border: "1px solid #979797",
     width:"35%",

    },
  maintableHead: {
    backgroundColor: "#F5F5F5",
  },
  Tcell3:{
    fontSize:"14px",
    fontFamily:"Open Sans",
    color:"black",
    fontWeigth: 600
  },
  Tcell2:{
    fontSize:"14px",
    fontFamily:"Open Sans",
    paddingLeft:"35px",
    color:"black",
    fontWeight: 600
  },
   EditBtn:{
     backgroundColor: "FDD100", 
     fontFamily: 'Open Sans', 
     padding:"15px 20px 15px 20px",
     color: "black", 
     width: "200px", 
     borderRadius:"10px", 
     fontWeight: 600, 
     fontSize: "16px", 
    textTransform: "none" 
   },
    buttonEditveiw:{
      backgroundColor: "FDD100", 
      fontFamily: 'Open Sans', 
      padding:"15px 20px 15px 20px",
      color: "black", 
      width: "200px", 
      fontSize: "16px", 
      fontWeight: 600, 
      borderRadius:"10px", 
        textTransform: "none" 
      },
  CancelBtn: {
    fontFamily: 'Open Sans', 
    fontSize: "medium", 
    fontWeight: 550, 
    backgroundColor: "white", 
    width: "170px", 
    textTransform: "none",
    color: "black", 
  },
  SubmitBtn:{
    backgroundColor: "FDD100", 
    marginRight: "20px",
    fontFamily: 'Open Sans', 
    fontSize: "medium", 
    color: "black", 
    textTransform: "none" ,
    width: "170px", 
    fontWeight: 550, 
  },
  leftSide2: {
    padding: "19px 0px 21px 38px"
  },
  informContainerveiw: { 
    padding: "0 32px", 
    flexDirection: "column" ,
    alignItems: "baseline", 
  },
  informSubContainerveiw: { 
    marginTop: "24px", 
    width: "800px", 
  },
  informationSubContainerTableview: {
    width: "90%" ,
    marginTop: "24px",
    },
  informationSubContainerHeadingveiw: { 
    marginTop: "24px",
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "initial", 
     width: "90%" 
    },
  container: {
    width: "100%",
    height: "100%"
  },
  top30: { marginTop: "30px" },
  labelStyle100veiw: {
    color: "black",
    fontSize: "18px",
    fontWeight: "300",
    fontFamily:"Open Sans",
  },
  labelStylemainHeader: {
    fontSize: "18px",
    fontFamily:"Open Sans",
    color: "#c8ccc9",
    fontWeight: 700,
  },
  mainvalueStyle: {
    color: "black",
    marginTop: "10px",
    fontSize: "16px",
    fontFamily:"Open Sans",
    fontWeight: 400,
    },
 
  labelStylesecondveiw: {
    fontSize: "18px",
    fontFamily:"Open Sans",
    color: "black",
  },
  labelStyleveiw: {
    color: "black",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily:"Open Sans",
  },
  rowgridDisplayveiw: { 
     display: "flex",
     alignItems: "center" },
  inputmainFieldTable: {
    background: 'transparent',
    border: '0.5px solid #979797',
    width: "70%",
  },
  Tcell:{
    fontSize:"14px",
    fontFamily:"Open Sans",
    color:"black"
  },
  Removebutton:{
    border:"1px solid #ff5c5c", 
    fontFamily: 'Open Sans',
    backgroundColor: "White", 
    width: "145px", 
    color: "black", 
    fontWeight: 600, 
    fontSize: "16px",
    textTransform: "none",
    paddingTop: "12px",
    borderRadius:"10px",
    paddingBottom:"12px",
  },
  myBox2:{
    marginTop: 10,
    bottom:"6px",
    width: "145px" ,
    marginLeft: 0,
    border:"1px solid #979797", 
    borderRadius: "12px",
  },
  mySelect:{
    background:"none",
    border:"none",
    padding:"15px",
     width:"135",
  },
  myBox:{
    width: "145px",
    border:"1px solid #979797",
    bottom:"6px",
    marginTop: 10, 
    borderRadius: "12px",
    marginLeft: 0,
  },
  inputtext:{
    background: 'transparent', 
    border: '0.5px solid #979797',
 width:"100%"
  },
   maincontainerveiw: {
     marginRight: "-20px",
     height: "64px",
     background: "#F3F4F5",
  },
  nullContainerveiw: { 
    padding: "0px 44px 0px 0px" 
  },

 

};
// Customizable Area End
