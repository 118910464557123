import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import {
  notification,
  setting,
  logout,
  dislike,
  like,
  badge,
  employeeId,
  role,
  headOffice,
  contactInfo,
  email,
  time,
  timezone,
  state,
  employee_notification,
  employee_profile,
  search_icon
} from "./assets";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DashboardController, { Props, configJSON } from "./DashboardController";
import "./dashboard.css";

export default class Navbar extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const name = window.localStorage.getItem("adminName");
    const editEmployee_id = window.localStorage.getItem("editEmployeeID");
    const editEmpoyee_name = window.localStorage.getItem("editEmployeeName");
    const profile_img = window.localStorage.getItem("profileImg");
    const path = window.location.pathname;

    const isUsersPath = path.startsWith("/users/");
    const path1 = path.length > "/users/".length;
    const notificationOpenClassName =
      this.state.openNotification || this.state.flagButton
        ? "notification-popup-open"
        : "";
    const ListItem = ({ imgSrc , count } : {imgSrc : any , count : number}) => {
      return (
        <li>
          <span style={{width:'45px',height:'45px'}}>
            <img style={{height:'70px'}} src={imgSrc} alt="" width={40} height={40} />
          </span>
          <p>{count}</p>
        </li>
      );
    };
    return (
      // Customizable Area Start

      <>
        {this.state.sideBarFlag ? (
          <div className={`header-wrapper ${notificationOpenClassName}`}>
            <div className="row">
              <div className="search-box">
                {isUsersPath && path1 ? (
                  <div className="user-action">
                    <div className="bake">
                      <Link to="/users">
                        <ArrowBackIosIcon />
                      </Link>
                    </div>
                    <div className="user-info">
                      <h4>{editEmpoyee_name}</h4>
                      <label>
                        {editEmployee_id !== "null" ? editEmployee_id : ""}
                      </label>
                    </div>
                  </div>
                ) : (
                  <form className="form search-form">
                    <div className="form-group">
                      <input
                        type="search"
                        className="form-input"
                        placeholder="Search…"
                        name="search"
                      />
                    </div>
                    <div className="form-group">
                      <button type="submit">
                        <SearchIcon
                          style={{
                            backgroundColor: "F4F4F5",
                            color: "#9797a1",
                            height: "19px",
                            width: "19px"
                          }}
                        />
                      </button>
                    </div>
                  </form>
                )}
              </div>
              <div className="header-right">
                <ul className="account-link">
                <li>
                    <img
                      src={setting}
                      style={{ cursor: "pointer" }}
                      height={30}
                      width={30}
                    />
                  </li>
                  <li>
                    <img
                      data-test-id="notification"
                      style={{ cursor: "pointer" }}
                      src={employee_notification}
                      height={30}
                      width={30}
                      onClick={this.togglePopup}
                    />
                  </li>
                  <li>
                    <img
                      data-test-id="ovalprofile"
                      style={{ cursor: "pointer", borderRadius: '30px', height: '48px', width: '48px'  }}
                      src={this.state?.profile_img}
                      height={30}
                      width={30}
                      onClick={this.handleUploadClick}
                    />
                  </li>
                  <li id="employee-profile-name-details">
                  <h3 style={{fontWeight:'normal',color:'black',marginBottom:'0px'}}>{this.state.editAdminFormData?.attributes?.first_name + " " + this.state.editAdminFormData?.attributes?.last_name}</h3>
                  <span style={{marginTop:'0px',opacity:'100%'}}>{this.state.editAdminFormData?.attributes?.designation}</span>
                  </li>
                </ul>
              </div>
            </div>

            {this.state.openNotification && (
              <>
                <div className="popup-overlay header-light" />
                <div className="notification-popup-overlay" />
                <div className="notification-popup-menu">
                  <div className="notification-popup">
                    <div className="popup-header">
                      <h5>Notifications</h5>
                      <span className="close" onClick={this.togglePopup} />
                    </div>
                    <div className="notification-items">
                      <div className="notification-item">
                        <h5>Congratulations!</h5>
                        <div className="notification-content">
                          <p>Your Leave request has been approved.</p>
                        </div>
                        <div className="notification-detail">
                          <span>Oct 20, 2022 (08:00 pm)</span>
                          <a href="#">clear</a>
                        </div>
                      </div>
                      <div className="notification-item">
                        <h5>Congratulations!</h5>
                        <div className="notification-content">
                          <p>
                            Your Leave request has been approved. Your Leave
                            request has been approved. Your Leave request has
                            been approved. Your Leave request has been approved.
                          </p>
                        </div>
                        <div className="notification-detail">
                          <span>Oct 20, 2022 (08:00 pm)</span>
                          <a href="#">clear</a>
                        </div>
                      </div>
                      <div className="notification-item">
                        <h5>Congratulations!</h5>
                        <div className="notification-content">
                          <p>Your Leave request has been approved.</p>
                        </div>
                        <div className="notification-detail">
                          <span>Oct 20, 2022 (08:00 pm)</span>
                          <a href="#">clear</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
             {this.state.flagButton && (
                    <>
                      <div className="popup-overlay header-light" />
                      <div className="notification-popup-overlay" />
                      <div className="admin-profile-popup">
                        <div className="notification-popup-menu">
                          <div className="notification-popup">
                            <div className="notification-popup profile-popup">
                              <div className="popup-header">
                                <h5>Profile</h5>
                                <span
                                  className="close"
                                  onClick={this.handleUploadClick}
                                />
                              </div>
                              <div className="admin-profile-details">
                                <img
                                  data-test-id="ovalprofileImage"
                                  src={this.state?.profile_img}
                                  height={30}
                                  width={30}
                                />
                                <h3 style={{fontWeight:'bold',color:'black'}}>{this.state.editAdminFormData?.attributes?.first_name + " " + this.state.editAdminFormData?.attributes?.last_name}</h3>
                                <small style={{color:"#0082D3"}}>{this.state.editAdminFormData?.attributes?.designation}</small>
                                <ul id="secondlist">
                                  <ListItem imgSrc={like} count={1} />
                                  <ListItem imgSrc={dislike} count={1} />
                                  <ListItem imgSrc={badge} count={3} />
                                </ul>
                              </div>
                              <div className="admin-profile-nav">
                                <div className="admin-profile-heading">
                                  <h3 style={{fontWeight:'bold'}}>Personal Info</h3>
                                  <Link
                                    to="/EmployeeProfiles"
                                    onClick={this.handleUploadClick}
                                  >
                                    View All
                                  </Link>
                                </div>
                                <div>
                                  <div className="empContainer" style={{color:'black'}}>
                                    <div className="empContainer-first">{configJSON.firstname}</div>
                                    <div className="empContainer-second">{this.state.editAdminFormData?.attributes?.first_name}</div>
                                  </div>
                                  <div className="empContainer" style={{color:'black'}}>
                                    <div className="empContainer-first">{configJSON.lastname}</div>
                                    <div className="empContainer-second">{this.state.editAdminFormData?.attributes?.last_name}</div>
                                  </div>
                                  <div className="empContainer">
                                    <div className="empContainer-first">{configJSON.emailID}</div>
                                    <div className="empContainer-second">{this.state.editAdminFormData?.attributes?.email}</div>
                                  </div>
                                  <div className="empContainer" style={{color:'black'}}>
                                    <div className="empContainer-first">{configJSON.preferredPronoun}</div>
                                    <div className="empContainer-second">Shreya</div>
                                  </div>
                                  <div className="empContainer" style={{color:'black'}}>
                                    <div className="empContainer-first">{configJSON.personalPhoneNumber}</div>
                                    <div className="empContainer-second" style={{color:'#0082d3'}}>{this.state.editAdminFormData?.attributes?.phone_number}</div>
                                  </div>
                                  <div className="empContainer" style={{color:'black'}}>
                                    <div className="empContainer-first">{configJSON.hometown}</div>
                                    <div className="empContainer-second">{this.state.editAdminFormData?.attributes?.home_town}</div>
                                  </div>
                                  <div className="empContainer" style={{color:'black'}}>
                                    <div className="empContainer-first">{configJSON.address}</div>
                                    <div className="empContainer-second">{this.state.editAdminFormData?.attributes?.address}</div>
                                  </div>
                                  <div className="empContainer" style={{color:'black'}}>
                                    <div className="empContainer-first">{configJSON.bloodgroup}</div>
                                    <div className="empContainer-second">{this.state.editAdminFormData?.attributes?.blood_group}</div>
                                  </div>
                                  <div className="empContainer" style={{color:'black'}}>
                                    <div className="empContainer-first">{configJSON.dateOfBirth}</div>
                                    <div className="empContainer-second">{this.state.editAdminFormData?.attributes?.date_of_birth}</div>
                                  </div>
                                </div>
                                <div className="admin-profile-button">
                                  <button
                                    style={{ cursor: "pointer" }}
                                    type="button"
                                    data-test-id="logout"
                                    className="log-out-btn"
                                    onClick={() => this.logout()}
                                  >
                                    <span>
                                      <img src={logout} alt="logo" />
                                    </span>
                                    Logout
                                  </button>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
          </div>
        ) : (
          <div className={`header-wrapper ${notificationOpenClassName}`}>
            <div className="row">
              <div className="search-box">
                <form className="form search-form">
                  <div className="form-group">
                    <input
                      type="search"
                      className="form-input"
                      placeholder="Search Services"
                      name="search"
                    />
                  </div>
                  <div className="form-group">
                    <button type="submit">
                      <img src={search_icon} alt="search-icon" />
                    </button>
                  </div>
                </form>
              </div>
              <div className="header-right">
                <ul className="account-link">

                  <li>
                    <img
                      data-test-id="notification"
                      style={{ cursor: "pointer" }}
                      src={notification}
                      height={30}
                      width={30}
                      onClick={this.togglePopup}
                    />
                  </li>
                  <li>
                    <img
                      onClick={this.handleUploadClick}
                      src={this.state?.profile_img as string}
                      height={48}
                      width={48}
                      style={{ borderRadius: '30px', height: '48px', width: '48px' }}
                    />
                  </li>
                </ul>
              </div>
            </div>
            {this.state.openNotification && (
              <>
                <div
                  className="popup-overlay header-light"
                  id="employee-header"
                />
                <div
                  className="notification-popup-overlay"
                  id="employee-header-overlay"
                />
                <div
                  className="notification-popup-menu"
                  id="employeee-header-menu"
                >
                  <div
                    className="notification-popup"
                    id="employeee-header-popup"
                  >
                    <div className="popup-header" id="popup-header">
                      <h5>Notifications</h5>
                      <span
                        className="close"
                        data-testId="closePopUp"
                        onClick={this.togglePopup}
                      />
                    </div>
                    <div
                      className="notification-items"
                      data-testId="notificationItems"
                    >
                      <div
                        className="notification-item"
                        id="employee-notification-item"
                      >
                        <h5>Congratulations!</h5>
                        <div
                          className="notification-content"
                          id="employee-notification-content"
                        >
                          <p>Your Leave request has been approved.</p>
                        </div>
                        <div
                          className="notification-detail"
                          id="employee-notification-detail"
                        >
                          <span>Oct 20, 2022 (08:00 pm)</span>
                          <a href="#">clear</a>
                        </div>
                      </div>
                      <div
                        className="notification-item"
                        id="employee-notification-item-1"
                      >
                        <h5>Congratulations!</h5>
                        <div
                          className="notification-content"
                          id="employee-notification-content-1"
                        >
                          <p>
                            Your Leave request has been approved. Your Leave
                            request has been approved. Your Leave request has
                            been approved. Your Leave request has been approved.
                          </p>
                        </div>
                        <div
                          className="notification-detail"
                          id="employee-notification-detail-1"
                        >
                          <span>Oct 20, 2022 (08:00 pm)</span>
                          <a href="#">clear</a>
                        </div>
                      </div>
                      <div
                        className="notification-item"
                        id="employee-notification-item-2"
                      >
                        <h5>Congratulations!</h5>
                        <div
                          className="notification-content"
                          id="employee-notification-content-2"
                        >
                          <p>Your Leave request has been approved.</p>
                        </div>
                        <div
                          className="notification-detail"
                          id="employee-notification-detail-2"
                        >
                          <span>Oct 20, 2022 (08:00 pm)</span>
                          <a href="#">clear</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="notification-btn"
                    data-testId="notificatinButton"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Link
                      onClick={this.togglePopup}
                      data-testId="notificationLink"
                      to="/Notifications"
                      style={{ color: "#327EE9" }}
                    >
                      {"Show More >"}
                    </Link>
                  </div>
                </div>
              </>
            )}

            {this.state.flagButton && (
              <>
                <div
                  className="popup-overlay header-light"
                  id="employee-profile-popup-overlay header-light"
                />
                <div
                  className="notification-popup-overlay"
                  id="employee-profile-notification-popup-overlay"
                />
                <div
                  className="admin-profile-popup"
                  id="employee-profile-popup"
                >
                  <div
                    className="notification-popup-menu"
                    id="employee-profile-popup-menu"
                  >
                    <div
                      className="notification-popup"
                      id="employee-profile-notification-menu"
                    >
                      <div
                        className="notification-popup profile-popup"
                        id="employee-profile-notification-profile-popup"
                      >
                        <div
                          className="popup-header"
                          id="employee-profile-popup-header"
                        >
                          <h5>Profile</h5>
                          <span
                            className="close"
                            data-testId="closeProfile"
                            onClick={this.handleUploadClick}
                          />
                        </div>
                        <div
                          className="admin-profile-details"
                          id="employee-profile-profile-details"
                        >
                          <img
                            src={this.state?.profile_img as string}
                            height={30}
                            width={30}
                          />
                            <h3 style={{fontWeight:'bold'}}>{this.state.editAdminFormData?.attributes?.first_name + " " + this.state.editAdminFormData?.attributes?.last_name}</h3>
                            <span style={{color:"#0082D3"}}>{this.state.editAdminFormData?.attributes?.role}</span>
                          <ul id="firstlist">
                            <ListItem imgSrc={like} count={1} />
                            <ListItem imgSrc={dislike} count={1} />
                            <ListItem imgSrc={badge} count={3} />
                          </ul>
                        </div>
                        <div
                          className="admin-profile-nav"
                          id="employee-profile-profile-nav"
                        >
                          <div
                            className="admin-profile-heading"
                            id="employee-profile-profile-heading"
                          >
                            <h3>About Me</h3>
                            <Link
                              to="/CustomisableUserProfiles"
                              onClick={this.handleUploadClick}
                            >
                              View All
                            </Link>
                          </div>
                          <ul className="employee-list-of-profile">
                            <li aria-disabled id="employee-profile-id">
                              <img src={employeeId} alt="logo" />
                              <p>{this.state.editAdminFormData?.attributes?.employee_id}</p>
                            </li>
                            <li id="employee-profile-mail">
                              <img
                                src={email}
                                alt="logo"
                                id="employee-profile-mail"
                              />
                              <p>
                                <a href="#">{this.state.editAdminFormData?.attributes?.email}</a>
                              </p>
                            </li>
                            <li id="employee-profile-role">
                              <img src={role} alt="logo" />
                              <p>{this.state.editAdminFormData?.attributes?.designation}</p>
                            </li>
                            <li id="employee-profile-shift">
                              <img src={time} alt="logo" />
                              <p>{this.state.editAdminFormData?.attributes?.timings}</p>
                            </li>
                            <li id="employee-profile-location">
                              <img src={headOffice} alt="logo" />
                              <p>{this.state.editAdminFormData?.attributes?.city}</p>
                            </li>
                            <li id="employee-profile-timeZOne">
                              <img src={timezone} alt="logo" />
                              <p>{this.state.editAdminFormData?.attributes?.zone}</p>
                            </li>
                            <li id="employee-profile-contact">
                              <img src={contactInfo} alt="logo" />
                              <p>
                                <a href="#">{this.state.editAdminFormData?.attributes?.phone_number}</a>
                              </p>
                            </li>
                            <li id="employee-profile-hometown">
                              <img src={state} alt="logo" />
                              <p>{this.state.editAdminFormData?.attributes?.state}</p>
                            </li>
                          </ul>
                          <div
                            className="admin-profile-button"
                            id="employee-profile-profile-button"
                          >
                            <button
                              style={{ cursor: "pointer" }}
                              type="button"
                              data-test-id="logoutButton"
                              className="log-out-btn"
                              onClick={() => this.logout()}
                            >
                              <span>
                                <img src={logout} alt="logo" />
                              </span>
                              Logout
                            </button>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </>

      // Customizable Area End
    );
  }
}
