import React from "react";
// Customizable Area Start
import "./dashboard.css";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import "react-circular-progressbar/dist/styles.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import {Button,Modal} from '@material-ui/core';
import PersonIcon from "@material-ui/icons//Person";
import { LineChart, Line, XAxis, YAxis, Tooltip, LabelList, ReferenceLine } from "recharts";
import { AntSwitch } from "./Users.web";
import { webStyle } from "./EditTemplate.web";
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";


export default class Dashboard extends DashboardController {

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const name = window.localStorage.getItem("adminName")
    const medianValue1 = this.calculateMedian(this.state.dataSource);
    const medianValue2 = this.calculateMedian(this.state.dataSource2);
    return (
      // Customizable Area Start
      <div className="content-wrapper">
        <div className="dashboard-info">
          <div className="welcome-user">
            <h2>Hi, {name}</h2>
            <div className="row">
              <div className="col">
                <div className="inner-col" style={{ background: " #6191C7" }}>
                  <h2>{this.state.totalTemplate}</h2>
                  <p>Templates Created</p>
                </div>
              </div>
              <div className="col">
                <div className="inner-col" style={{ background: "#486484" }}>
                  <h2>{this.state.activeUsersCount}</h2>
                  <p>Active Users</p>
                </div>
              </div>
              <div className="col">
                <div className="inner-col" style={{ background: "#4E53A6" }}>
                  <h2>115</h2>
                  <p>Templates Assigned</p>
                </div>
              </div> 

            </div>
          </div>
          <div className="upload-user-data">
            <button style={{cursor:"pointer"}} className="button" onClick={this.handleUploadClick}>
              + Upload User Data
            </button>
            {this.state.flagButton &&(
               <Box sx={{ minWidth: 40 }}>
               <Card
                 variant="outlined"
                 style={{
                   width: 210,
                   marginTop:"10px",
                   height: 80.23,
                   border: "none", // Remove the black border
                   boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Add a shadow
                   display: "flex",
                   flexDirection: "column",
                   justifyContent: "center",
                   alignItems: "flex-start",
                   padding: "8px"
                 }}
               >
                 <Button
                 data-test-id="singleuser"
                   onClick={()=> this.props.navigation.navigate("SingleUserUpload")}
                   style={{ textAlign: "left", textTransform: "none" }}
                 >
                   <label  style={{ fontSize: 12 }}>
                   <PersonIcon style={{ fontSize: 14, marginRight: 1, width:16,height:16 }} />
                   Single User Upload
                   </label>
                 </Button>
                 <Button
                 
                   style={{ textAlign: "left", textTransform: "none" }}
                 >
                  <label htmlFor="bulk-upload" style={{ fontSize: 12 }}>
                    <PersonIcon style={{ fontSize: 18, marginRight: 1 ,width:16,height:16}} />
                    Bulk Upload
                  </label>
                 <input accept=".csv" type="file" style={{display:'none'}} onChange={(e)=>{this.handleFileChange(e)}} id="bulk-upload" />
                

                 </Button>
               </Card>
             </Box>
            )
            }
          </div>
        </div>
        <div className="dashboard-chart-block">
          <div className="graph-row">
            <div className="col">
              <div className="dashboard-chart">
                <ReactApexChart
                  options={this.state.options}
                  series={this.state.series}
                  type="bar"
                  height={350}
                  width={500}

                />
              </div>
            </div>
            <div className="col">
              <div className="dashboard-chart">
                <LineChart
                  layout="horizontal"
                  width={500}
                  height={360}
                  data={this.state.dataSource}
                  margin={{
                    top: 60,
                    right: 30,
                    left: 20,
                    bottom: 10
                  }}
                >
                  <XAxis dataKey="name" type="category" />
                  <YAxis type="number" />
                  <Tooltip />
                  <text
                    x={30}
                    y={30}
                    textAnchor="right"
                    fontWeight="bold"
                    style={{ fontSize: '20px', fontWeight: 'bold' }}
                    fill="#a0a0a0"
                  >
                    Performance (Last Quarter)
                  </text>
                  <Line dataKey="pv" stroke="#FCD61D" dot={{ fill: "#486484" }}>
                    <LabelList dataKey="pv" position="top" />
                  </Line>
                 
                    <ReferenceLine
                      y={medianValue1}
                      stroke="#E4E4E4"
                      strokeDasharray="7 6"
                      width={249.9}
                      style={{
                        strokeWidth: "3px"
                      }}
                    />
                 
                </LineChart>
              </div>
            </div>
            <div className="col">
              <div className="dashboard-chart">
                <LineChart
                  layout="horizontal"
                  width={500}
                  height={360}
                  data={this.state.dataSource2}
                  margin={{
                    top: 60,
                    right: 30,
                    left: 20,
                    bottom: 10
                  }}
                >
                  <XAxis dataKey="name" type="category" />
                  <YAxis type="number" />
                  <Tooltip />
                  <text
                    x={30}
                    y={30}
                    textAnchor="right"
                    fontWeight="bold"
                    fill="#a0a0a0"
                    style={{ fontSize: '20px', fontWeight: 'bold' }}
                  >
                    User Growth (Last Quarter)
                  </text>

                  <Line dataKey="pv" stroke="#486484" dot={{ fill: "#9BBE6A" }}>
                    <LabelList dataKey="pv" position="top" />
                  </Line>

                  
                    <ReferenceLine
                      y={medianValue2}
                      stroke="#E4E4E4"
                      strokeDasharray="7 6"
                      width={249.9}
                      style={{
                        strokeWidth: "3px"
                      }}
                    />
                  
                </LineChart>

              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-table">
          <div className="table-header">
            <h4>Employee Details</h4>
            <Link to="/users">View All</Link>
          </div>
          <div className="responsive-table">
            <table className="table" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th>Employee ID</th>
                  <th>Name</th>
                  <th>Department</th>
                  <th>KPI Progress</th>
                  <th>Enable/Disable</th>
                
                 
                </tr>
              </thead>
              <tbody className="tbody">
                {this.state.viewAllUserData.map((item: any, key: number) => {
                  return (
                    <>
                    <tr data-test-id="rows" key={key} className="tabel-rows">
                      <td className={this.getClassName(item.attributes.activated)} data-id="table-id">
                        {item.attributes.employee_id
                          ? item.attributes.employee_id
                          : "-"}
                      </td>
                      <td className={this.getClassName(item.attributes.activated)} data-test-id="attr-name-id">
                        <span className="attr-name"> {item.attributes.name
                          ? item.attributes.name
                          : "-"}</span>
                      </td>
                      <td className={this.getClassName(item.attributes.activated)}>
                        {item.attributes.department?.name
                          ? item.attributes.department?.name
                          : "-"}
                      </td>
                      <td className={this.getClassName(item.attributes.activated)}>
                    
                            <Box position="relative" display="inline-flex" className="faleclass">
                              <CircularProgress
                              data-test-id="progress"
                                variant="determinate"
                                value={item.attributes.kpis[0]?.progress}
                                style={this.getStatusStyle(item.attributes.kpis[0]?.progress !== undefined, !item.attributes.activated)}
                              />
                              <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position="absolute"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Typography
                                data-test-id="typography"
                                  variant="caption"
                                  component="div"
                                  color="textSecondary"
                                >{item.attributes.kpis[0]?.progress? item.attributes.kpis[0]?.progress+"%":""}</Typography>
                              </Box>
                            </Box>
                          
                        </td>
                          <td className={this.getClassName(item.attributes.activated)} id="fake">
                            <AntSwitch
                           data-test-id="emp_switch"
                              checked={
                                item.attributes.activated}
                              onClick={() =>
                               { this.handleSwitchChange(item.id,item.attributes.name);this.handleOpenKpiModal()}
                              }
                              name="checked"
                              id={`switch-${item.id}`}
                            />
                          </td>
                     
                    </tr>
                   
                </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                        open={this.state.disableKpiModel}
                                        onClose={this.handleOpenKpiModal}
                                        aria-labelledby="simple-modal-title"
                                        aria-describedby="simple-modal-description"
                                    >
                                        <>
                                            <div style={webStyle.kpiModel as React.CSSProperties}>

                                                <Typography style={{
                                                    color: "#000",
                                                    fontSize: "18px",
                                                    fontStyle: "normal", fontWeight: 700, lineHeight: "normal", padding: "72px 0px 79px 66.93px"
                                                }}>Are you sure you want to disable/enable the account of {this.state.accountsName} ? </Typography>
                                                <div style={webStyle.KpiModelfooterStyle}>
                                                    <Button data-test-id="save" onClick={() => {this.confirmDisableAccount()}} style={webStyle.buttonsubmit}
                                                    >Done</Button>
                                                    <div style={webStyle.buttoncancel}>
                                                        <Button

                                                            onClick={this.handleOpenKpiModal}
                                                            variant="outlined"
                                                            style={{
                                                                width: "100%",
                                                                maxWidth: "101px",
                                                                borderRadius: "8px",
                                                                height: "40px",
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                    </Modal>
      </div>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End

