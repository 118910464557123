import React from "react";
// Customizable Area Start
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Divider,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Table,
  TableHead,
  Typography
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Customizable Area End
import AddDkrController, { Props } from "./AddDkrController";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class VeiwKpi extends AddDkrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.MainContainer}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid className="first" style={webStyle.LeftGridSide} item>
              <Grid container spacing={2} alignItems="center">
                <Grid item style={webStyle.BackNavigation}>
                  <ArrowBackIosIcon style={{ fontSize: "24px", color: "black" }} data-test-id="nextButton1" onClick={this.navigateToEditTemplate2} />
                  <span
                    style={webStyle.HeaderStyle}>
                    Edit Template
                  </span>
                </Grid>
                <Grid item style={webStyle.BackNavigation}>
                  <ArrowBackIosIcon style={{...webStyle.HeaderStyle,color:"#B2B2B2"}} />
                  <span style={webStyle.labelsecondStyle as React.CSSProperties}>
                    View KPI Details
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            style={webStyle.InformainContainerviewkpi as React.CSSProperties}>
            <Grid
              className="first"
              item
              style={webStyle.inforsecondmainContainerHeadviewkpi}
              xs={12}>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={{marginTop:"20px"}}>
                    <span style={webStyle.HeaderTopStyleviewkpi as React.CSSProperties}>KPI Details</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2">
                <Button style={webStyle.buttonEditviewkpi as React.CSSProperties} data-test-id="nextButton" onClick={this.navigateToEditKpi}>
                Edit KPI Details
            </Button>
                </div>
              </div>

            </Grid>
            <Grid
              className="first"
              item
              style={{marginTop:"30px"}}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.headerTopMainviewkpi}>
                  <span style={{...webStyle.labelStyleOptionheadingviewkpi as React.CSSProperties,fontSize:"22px"}}>Type</span>
                </FormLabel>
                <span style={webStyle.ShadowStyleviewkpi as React.CSSProperties}>Core</span>
              </FormControl>
            </Grid>
            <Grid
              className="second"
              item
              style={webStyle.informationForsubjectviewkpi}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{...webStyle.headerTopMainviewkpi,marginTop:"30px"}}>
                  <span style={webStyle.labelStyleOptionheadingviewkpi as React.CSSProperties}>Basic Details</span>
                </FormLabel>
                <Grid container style={{ display: "flex" }} xs={12} spacing={6}>
                  <Grid item xs={3}>
                    <div className="form-block" id="signupBlock3">
                      <form>
                        <div
                          className="form-field"
                          id="signupform"
                          style={webStyle.DecriptionTop}
                        >
                          <span style={webStyle.TopstyleHeader as React.CSSProperties}>Title</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.LeftMarginviewkpi}
                          >
                            <span style={webStyle.ShadowStyleviewkpi as React.CSSProperties}>KPI-1</span>

                          </div>
                        </div>
                      </form>
                    </div>
                  </Grid>
                  
                    <>
                    <Grid item xs={6}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                        <div
                          className="form-field"
                          id="signupform"
                          style={webStyle.DecriptionTop}
                        >
                          <span style={webStyle.TopstyleHeader as React.CSSProperties}>Description</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.LeftMarginviewkpi}
                          >
                            <span style={webStyle.ShadowStyleviewkpi as React.CSSProperties}>Sales Goal<br />Sales Goal : 1000000<br />Pro Rata Basis<br />MQR : 70%</span>
                          </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                  <Grid item xs={3}>
                    <div className="form-block" id="signupBlock3">
                      <form>
                        <div
                          className="form-field"
                          id="signupform"
                          style={{...webStyle.DecriptionTop,width:"max-content"}}
                        >
                          <span style={webStyle.TopstyleHeader as React.CSSProperties}>Points (Weightage)</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.LeftMarginviewkpi}
                          >
                            <span style={webStyle.ShadowStyleviewkpi as React.CSSProperties}>20 points</span>
                          </div>
                        </div> 
                      </form>
                    </div>
                  </Grid>
                  </>
                </Grid>
              </FormControl>
            </Grid >
        
            <Grid
              className="third"
              item
              style={{marginTop:"55px"}}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.headerTopMainviewkpi}>
                  <span style={webStyle. labelStyleOptionheadingviewkpi as React.CSSProperties}>Calculation Type</span>
                </FormLabel>
                <div className="form-block" id="signupBlock3">
                  <form>
                    <div
                      className="form-field"
                      id="signupform"
                      style={webStyle.DecriptionTop}
                    >
                      <span style={webStyle.TopstyleHeader as React.CSSProperties}>Automatic</span>
                      <div
                        className="control"
                        id="signupfieldcontrol"
                        style={webStyle.LeftMarginviewkpi}
                      >
                        <span style={webStyle.ShadowStyleviewkpi as React.CSSProperties}>Yes</span>
                      </div>
                    </div>
                  </form>
                </div>
              </FormControl>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.informationForsubjectviewkpi}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.headerTopMainviewkpi}>
                  <span style={webStyle.labelStyleOptionheadingviewkpi as React.CSSProperties}>Monthly Goal Details</span>
                </FormLabel>
              </FormControl>
              <Grid container style={{ display: "flex" }} xs={12} spacing={6}>
                <Grid item xs={3} >
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        className="form-field"
                        id="signupform"
                        style={webStyle.DecriptionTop}
                      >
                        <span style={webStyle.TopstyleHeader as React.CSSProperties}>Goal Type</span>
                        <div
                          className="control"
                          id="signupfieldcontrol"
                          style={webStyle.LeftMarginviewkpi}
                        >
                      
                      <span style={webStyle.ShadowStyleviewkpi as React.CSSProperties}>Percentage</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        className="form-field"
                        id="signupform"
                        style={webStyle.DecriptionTop}
                      >
                        <span style={webStyle.TopstyleHeader as React.CSSProperties}>Goal Value (In Percentage)</span>
                        <div
                          className="control"
                          id="signupfieldcontrol"
                          style={webStyle.LeftMarginviewkpi}
                        >
                          <span style={webStyle.ShadowStyleviewkpi as React.CSSProperties}>100%</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.informationForsubjectviewkpi}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.headerTopMainviewkpi}>
                  <span style={webStyle.labelStyleOptionheadingviewkpi as React.CSSProperties}>Analytics and Run rate</span>
                </FormLabel>
              </FormControl>
              <Grid container style={{ display: "flex", alignItems: "last baseline" }}>
                <Grid item xs={3} spacing={1}>
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        className="form-field"
                        id="signupform"
                        style={webStyle.DecriptionTop}
                      >
                        <span style={webStyle.TopstyleHeader as React.CSSProperties}>Run rate</span>
                        <div
                          className="control"
                          id="signupfieldcontrol"
                          style={webStyle.LeftMarginviewkpi}
                        >
                          <span style={webStyle.ShadowStyleviewkpi as React.CSSProperties}>Yes</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        className="form-field"
                        id="signupform"
                        style={webStyle.DecriptionTop}
                      >
                        <span style={webStyle.TopstyleHeader as React.CSSProperties}>Part of Analytics</span>
                        <div
                          className="control"
                          id="signupfieldcontrol"
                          style={webStyle.LeftMarginviewkpi}
                        >
                          <span style={webStyle.ShadowStyleviewkpi as React.CSSProperties}>Yes</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        className="form-field"
                        id="signupform"
                        style={webStyle.DecriptionTop}
                      >
                        <span style={webStyle.TopstyleHeader as React.CSSProperties}>Analytics Parameter</span>
                        <div
                          className="control"
                          id="signupfieldcontrol"
                          style={webStyle.LeftMarginviewkpi}
                        >
                          <span style={webStyle.ShadowStyleviewkpi as React.CSSProperties}>Sales</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </Grid>
              </Grid>
            </Grid>
       
          </Grid >
          <Divider light style={{marginTop:"20px",marginBottom:"20px"}} />
           <Grid
            container
            alignItems="center"
            style={webStyle.InformainContainerviewkpi as React.CSSProperties}
          > 
            <Grid
              item
              xs={12}
              className="first"
              style={webStyle.inforsecondmainContainerHeadviewkpi}
              >
              <div>
                <FormControl 
                component="fieldset">
                  <FormLabel 
                  style={webStyle.headerTopMainviewkpi}
                  component="legend" 
                  >
                    <span style={webStyle.HeaderTopStyleviewkpi as React.CSSProperties}>Daily KPI Report

                    </span>
                  </FormLabel>

                </FormControl>
              </div>
              <div 
              className="form-field text-center" 
              id="signupBlock4"
              >
                <div 
                  id="signupfieldcontrol2"
                className="control" 
                >
                <Button 
                style={webStyle.buttonEditviewkpi as React.CSSProperties} 
                onClick={this.navigateToAddDKR}
                data-test-id="nextButton" 
                >
                Edit DKR Details
            </Button>
                </div>
              </div>

            </Grid>
            <Grid
              item
              className="first"
              xs={12}
              style={{marginTop: "20px"}}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" 
                style={webStyle.headerTopMainviewkpi}>
                  <span 
                  style={webStyle.labelStyleOptionheadingviewkpi as React.CSSProperties}>Calculation Type</span>
                </FormLabel>
                <div 
                          className="control"
                          id="signupfieldcontrol"
                          style={webStyle.LeftMarginviewkpi}
                        >
                        <span style={webStyle.ShadowStyleviewkpi as React.CSSProperties}
                        >
                          Slab
                        </span>
                        </div>
              </FormControl>
            </Grid>
             
            <Grid
              item
              xs={12}
              className="first"
              style={webStyle.informationForsubjectviewkpi}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" 
                style={webStyle.headerTopMainviewkpi}>
                  <span style={webStyle.labelStyleOptionheadingviewkpi as React.CSSProperties}>Define Calculation Type

                  </span>
                </FormLabel>
                  <div className="form-block" 
                  id="signupBlock3">
                    <form>
                      <Grid container xs={12} style={webStyle.gridAlignparent}>
                        <Grid item xs={2}>
                          <div
                            className="form-field"
                            id="signupform"
                            style={{...webStyle.DecriptionTop,width:"max-content"}}
                          >
                            <span style={webStyle.optionStyleTitle as React.CSSProperties}>No. of Slabs</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.LeftMarginviewkpi}
                            >
                             <Box
                                style={{ ...webStyle.inputFieldTabless, marginBottom: "10px" ,borderColor:"white" , display:"flex",justifyContent:"center"}}
                                className="control-input"
                                id="pronounce-control">
                                <Typography style={{fontFamily:"Open Sans",fontWeight:400,fontSize:"14px",color:"Black"}}>1.</Typography>
                                </Box>
                                <Box
                                style={{ ...webStyle.inputFieldTabless, marginBottom: "10px" ,borderColor:"white" , display:"flex",justifyContent:"center"}}
                                className="control-input"
                                id="pronounce-control">
                                <Typography style={{fontFamily:"Open Sans",fontWeight:400,fontSize:"14px",color:"Black"}}>2.</Typography>
                                </Box>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            className="form-field"
                            id="signupform"
                            style={webStyle.DecriptionTop}
                          >
                            <span style={{...webStyle.optionStyleTitle as React.CSSProperties,paddingLeft:"25px"}}>Min.</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.LeftMarginviewkpi}
                            >
                             <Box
                                style={{ ...webStyle.inputFieldTabless, marginBottom: "10px" ,borderColor:"white" , display:"flex",justifyContent:"center"}}
                                className="control-input"
                                id="pronounce-control">
                                <Typography 
                                style={{fontFamily:"Open Sans",fontWeight:400,fontSize:"14px",color:"Black"}}>0%</Typography>
                                </Box>
                                <Box
                                style={{ ...webStyle.inputFieldTabless, marginBottom: "10px" ,borderColor:"white" , display:"flex",justifyContent:"center"}}
                                className="control-input"
                                id="pronounce-control">
                                <Typography 
                                style={{fontFamily:"Open Sans",fontWeight:400,fontSize:"14px",color:"Black"}}>90.1%</Typography>
                                </Box>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            className="form-field"
                            id="signupform"
                            style={webStyle.DecriptionTop}>
                            <span style={{...webStyle.optionStyleTitle as React.CSSProperties,paddingLeft:"25px"}}>Max.</span>
                            <div
                              id="signupfieldcontrol"
                              className="control"
                              style={webStyle.LeftMarginviewkpi}>
                              <Box
                                style={{ ...webStyle.inputFieldTabless, marginBottom: "10px" ,borderColor:"white" , display:"flex",justifyContent:"center"}}
                                className="control-input"
                                id="pronounce-control">
                                <Typography style={{fontFamily:"Open Sans",fontWeight:400,fontSize:"14px",color:"Black"}}>90%</Typography>
                                </Box>
                                <Box
                                style={{ ...webStyle.inputFieldTabless, marginBottom: "10px" ,borderColor:"white" , display:"flex",justifyContent:"center"}}
                                className="control-input"
                                id="pronounce-control">
                                <Typography style={{fontFamily:"Open Sans",fontWeight:400,fontSize:"14px",color:"Black"}}>100%</Typography>
                                </Box>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            style={webStyle.DecriptionTop}
                            className="form-field"
                            id="signupform"
                          >
                            <span style={webStyle.optionStyleTitle as React.CSSProperties}>Weightage</span>
                            <div
                              className="control"
                              style={{...webStyle.LeftMarginviewkpi,marginTop:"8px"}}
                              id="signupfieldcontrol"
                            >
                              <input
                                type="text"
                                data-test-id="txtInputEmail"
                                style={{ ...webStyle.inputFieldTabless , marginBottom: "10px",textAlign:"center",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px", border: '0.5px solid white'}}
                                className="control-input"
                                name="email"
                                id="pronounce-control"
                                placeholder="2"
                                disabled
                              />
                          
                               <input
                                type="text"
                                data-test-id="txtInputEmail"
                                style={{ ...webStyle.inputFieldTabless , marginBottom: "10px",textAlign:"center",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px", border: '0.5px solid white'}}
                                className="control-input"
                                name="email"
                                 placeholder="8"
                                id="pronounce-control"
                                disabled
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
              </FormControl >
            </Grid >
            <Grid
              className="first"
              item
              style={webStyle.informatSubContainerTableviewkpi}
              xs={12}
            >
              <>
                <FormControl component="fieldset">
                  <FormLabel 
                  component="legend" style={webStyle.headerTopMainviewkpi}>
                    <span style={{ ...webStyle.labelStyleOptionheadingviewkpi as React.CSSProperties, fontWeight: 500 }}>Applicable DKRs</span>
                  </FormLabel>
                </FormControl>
                
                  <TableContainer 
                  style={{...webStyle.tableContainermainviewkpi,marginBottom:"10%"}}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow style={webStyle.tableHeadcolorviewkpi}>
                          <TableCell 
                          style={{...webStyle.Tablecell2,fontWeight:600}}>Sr. No.</TableCell>
                          <TableCell style={{...webStyle.TableCellviewkpi,fontWeight:600}}>DKR Type</TableCell>
                          <TableCell 
                          style={{...webStyle.Tableview,fontWeight: 600}}>
                            DKR Brief Title
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                        >
                          <TableCell style={{...webStyle.TableCell1,width:"12%"}}>
                            1.
                          </TableCell>
                          <TableCell style={webStyle.Captured}>
                            Captured DKR (Numerator)
                          </TableCell>
                          <TableCell>
                            <div className="form-block" id="signupBlock3">
                              <form>
                                <div
                                  className="form-field"
                                  id="signupform"                                >
                                  <div
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.LeftMarginviewkpi}
                                  >
                                     <span style={{fontFamily:"Open Sans",fontWeight:400,fontSize:"14px",color:"Black"}}>Todays Collection</span>
                                  </div>

                                </div>
                              </form>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                        >
                          <TableCell component="th" scope="row" style={webStyle.TableCell2}>
                            2.
                          </TableCell>
                          <TableCell style={webStyle.Capdenominator}>
                            Captured DKR (Denominator)
                          </TableCell>
                          <TableCell>
                            <div className="form-block" id="signupBlock3">
                              <form>
                                <div
                                  id="signupform"
                                  className="form-field"
                                >
                                  <div
                                    id="signupfieldcontrol"
                                    className="control"
                                    style={webStyle.LeftMarginviewkpi}
                                  >
                                     <span style={{fontFamily:"Open Sans",fontWeight:400,fontSize:"14px",color:"Black"}}>Total Collection</span>
                                  </div>

                                </div>
                              </form>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow
                        >
                          <TableCell component="th" scope="row" style={webStyle.TableCell3}>
                            3.
                          </TableCell>
                          <TableCell style={webStyle.Gamified}>
                            Gamified DKR
                          </TableCell>
                          <TableCell>
                            <div className="form-block" id="signupBlock3">
                              <form>
                                <div
                                  id="signupform"
                                  className="form-field"
                                >
                                  <div
                                    id="signupfieldcontrol"
                                    className="control"
                                    style={webStyle.LeftMarginviewkpi}
                                  >
                                     <span style={{fontFamily:"Open Sans",fontWeight:400,fontSize:"14px",color:"Black"}}>Total Collection</span>
                                  </div>

                                </div>
                              </form>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
              </>
            </Grid>
          </Grid > 
        
        </Box >
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  tablecell2:{
    fontSize:"14px",
    fontFamily:"Open Sans",
    color:"black",
    // fontWeight: 600
  },
  tablecell1:{
    color:"black",
    fontFamily:"Open Sans",
    fontSize:"14px",
    paddingLeft:"65px"
  },
  Selectt:{
    border: "none", 
    padding: "12px", 
    width: "100px" ,
    background: "none", 
  },
  selectBox:{
    backgroundColor: "F6F6F9", 
    width: "110px", 
    borderRadius: "12px", 
  },
  buttonEditviewkpi:{
    backgroundColor: "FDD100", 
    fontFamily: 'Open Sans', 
    padding:"15px 20px 15px 20px",
    color: "black", 
    width: "200px", 
    borderRadius:"10px", 
    fontWeight: 600, 
    fontSize: "16px", 
    textTransform: "none" 
  },
  MainContainer: {
    height: "64px",
    marginRight: "-20px",
    background: "#F3F4F5",
  },
  LeftGridSide: {
    padding: "19px 0px 21px 38px"
  },
  BackNavigation: {
    display: "flex",
    alignItems: "center",
  },
  HeaderStyle: {
    fontFamily: "Open Sans",
    color: "black",
    fontSize: "18px",
    fontWeight: 400
  },
  labelsecondStyle: {
    fontWeight: 700,
    color: "black",
    fontFamily: "Open Sans",
    fontSize: "18px",
  },CancelBtn: {
    fontFamily: 'Open Sans', 
    fontWeight: 550, 
    fontSize: "medium", 
    textTransform: "none",
    width: "170px", 
    color: "black", 
    backgroundColor: "white", 
  },
  SubmitBtn:{
    backgroundColor: "FDD100", 
    fontFamily: 'Open Sans', 
    color: "black", 
    marginRight: "20px",
    fontSize: "medium", 
    fontWeight: 550, 
    width: "170px", 
    textTransform: "none" ,
  },
  Tableview:{
    fontSize:"14px",
    color:"black",
    fontFamily:"Open Sans",
  },
TableCellviewkpi:{
  fontFamily:"Open Sans",
  fontSize:"14px",
color:"black",
  },
Tablecell2:{
  fontFamily:"Open Sans",
  paddingLeft:"35px",
  fontSize:"14px",
  color:"black",
},
   maincontainer: {
     background: "#F3F4F5",
     height: "64px",
    marginRight: "-20px"
  },
  InformainContainerviewkpi: { 
    padding: "0 32px", 
    flexDirection: "column",
    alignItems: "baseline", 
  },
  informationForsubjectviewkpi: { 
    width: "800px" ,
    marginTop: "55px", 
  },
  leftSide: {
    padding: "19px 0px 21px 38px"
  },
  nullContainer: { 
    padding: "0px 44px 0px 0px" 
  },
 
  informatSubContainerTableviewkpi: { 
    marginTop: "24px",
     width: "90%" 
    },
  inforsecondmainContainerHeadviewkpi: { 
    marginTop: "24px", 
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "initial", 
    width: "90%" 
  },
  DecriptionTop: { 
    marginTop: "23px" 
  },
  container: {
    height: "100%",
    width: "100%",
  },
  optionStyleTitle: {
    fontSize: "18px",
    color: "#c8ccc9",
    fontWeight: "bold",
    fontFamily:"Open Sans"
  },
  labelStyleOptionheadingviewkpi: {
    color: "black",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily:"Open Sans"
  },
  TopstyleHeader: {
    fontSize: "18px",
    color: "#c8ccc9",
    fontWeight: 700,
    fontFamily:"Open Sans"
  },
  ShadowStyleviewkpi: {
    color: "black",
    marginTop: "10px",
    fontSize: "16px",
    fontFamily:"Open Sans",
    fontWeight: 400
    },
  rowarrowDisplay: {
    alignItems: "center" ,
    display: "flex", 
    },
  HeadStyleTop1: {
    color: "black",
    fontFamily:"Open Sans",
    fontSize: "18px",
  },
  HeaderTopStyleviewkpi: {
    color: "black",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily:"Open Sans"
  },
  inputField: {
    width: "60%",
    border: '0.5px solid #979797',
    background: 'transparent',
  },
  inputFieldTabless: {
    width: "70%",
    border: '0.5px solid #979797',
    background: 'transparent',
  },
  LeftMarginviewkpi: {
    marginLeft: 0,
    marginTop: 10
  },
  removeButton: {
    background: "transparent",
    borderColor: '#FF5C5C',
    margin: "10px 0 0",
    width: "70%",
    fontSize:"16px",
    fontWeight:"400"
  },
  addButton: {
    background: "transparent",
    borderColor: '#FDD100',
    margin: "10px 0 0",
    width: "70%",
    fontSize:"16px",
    fontWeight:"400"
  },
  buttonmainContainer: { 
    display: "flex", 
    gap: "20px", 
    margin: "0 40px" },
  themeColorback: {
    color: "#FDD100"
  },
  headerTopMainviewkpi: {
    // display: "flex",
    // alignItems: "baseline",
    // gap: "4px",
    // MarginTop:"23px"
  },
  tableHeadcolorviewkpi: {
    backgroundColor: "#F5F5F5",
  },
  tableContainermainviewkpi: { 
  border: "1px solid #979797", 
  borderRadius: "10px",
  marginTop: "10px" },
  gridAlignparent: {
    alignItems: "end"
  },
  gamifieddkr: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    color: "black",
    fontWeight: 400
  },
  TableCell5: {
    fontFamily: "Open Sans",
    color: "black",
    fontSize: "14px",
    paddingLeft: "65px",
  },
  Captureddkr: {
    fontFamily: "Open Sans",
    color: "black",
    fontSize: "14px",
    fontWeight: 400
  },
  TableCell4: {
    fontFamily: "Open Sans",
    color: "black",
    paddingLeft: "65px",
    fontSize: "14px",
  },
  Gamified: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    width: "20%",
    color: "black",
  },
  TableCell3: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    paddingLeft: "65px",
    color: "black",
  },
  Capdenominator: {
    color: "black",
    fontFamily: "Open Sans",
    fontSize: "14px",
    width: "20%"
  },
  TableCell2: {
    color: "black",
    fontFamily: "Open Sans",
    paddingLeft: "65px",
    fontSize: "14px",
  },
  Captured: {
    fontSize: "14px",
    color: "black",
    fontFamily: "Open Sans",
    width: "20%"
  },
  TableCell1: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    paddingLeft: "65px",
    color: "black",
  },
};
// Customizable Area End
